<template>
  <v-app>
    <v-app-bar
      app
      color="accent"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Rivanna Technologies Logo"
          class="mr-1"
          max-height="75"
          max-width="75"
          src="./assets/rt.png"
          contain
        />

        
  <div class="d-none d-sm-block text-h5">Streamlined Document Submittal</div>

        
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view/>
      <v-footer
      color="secondary lighten-1"
      padless
    >
      
     <v-row justify="center" no-gutters class="d-none d-md-flex">        
        <v-btn height="75px" href="https://rivannatechnologies.com" class="my-2" color="#5CDB95" plain>Application developed by Rivanna Technologies LLC.</v-btn>
        
      </v-row>
      <v-row justify="center" no-gutters class="d-md-none">        
        <v-btn height="75px" href="https://rivannatechnologies.com" class="my-2" color="#5CDB95" plain>Developed by Rivanna Technologies LLC.</v-btn>
        
      </v-row>
      <!--<v-row justify="center" no-gutters class="d-none d-md-flex">
        <EULA />
      </v-row>-->
    </v-footer>
    </v-main>
  </v-app>
</template>

<script>
// import EULA from './components/EULA'
export default {
  name: 'App',
  components: {
    // EULA
  },
  created () {
    // this.$plausible.enableAutoPageviews();
  },
  data: () => ({
    //
  }),
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Rivanna Technologies LLC.'
    }
  }
};
</script>
