import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
// import { VuePlausible } from 'vue-plausible'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// Vue.use(VuePlausible, {
//   // see configuration section
//   domain: "vdot-demo.rivannatechnologies.com",
//   enableAutoPageviews: true
// })
const toastoptions = {
  // You can set your default options here
};
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { 
    id: "UA-226438114-1",
    params: {
      send_page_view: true
    } 
  }
}, router);
Vue.use(Toast, toastoptions);
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
