import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        themes: {
            light: {
                primary: '#5CDB95',
                secondary: '#011C36',
                accent: '#829298',
                error: '#CC0000',
                info: '#DBDBDB',
                success: '#3C5A14',
                warning: '#FC440F'
            }
        }
      }
});
